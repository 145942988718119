import {Injectable, NgZone} from '@angular/core';
import {Observable, Subject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {

  public loadingListener = new Subject<any>();
  constructor(private ngZone: NgZone) { }

  public showLoading() {
    this.loadingListener.next('show');
  }

  public hideLoading() {
    this.loadingListener.next('hide');
  }

  async presentLoading(loadingController: any) {
    const loading = await loadingController.create({
      message: 'Please wait ... ',
      duration: 100000
    });
    await loading.present();

    const {role, data} = await loading.onDidDismiss();
  }

  async closeLoading(loadingController: any) {
    this.ngZone.run(() => {
      loadingController.dismiss();
      console.log('Loading dismissed! ');
    });
  }

  public getLoadingListenerSubject(): Observable<any> {
    return this.loadingListener.asObservable();
  }

}
