 <div class="login-container container-fluid display-flex-center full-height">
    <div class="login">
     <h2 class="title">Login</h2>
     <form #loginForm class="login-form">
          <mat-form-field class="form-field">
            <mat-label>Enter your email</mat-label>
            <input matInput [formControl]="email" [(ngModel)]="admin.email" required>
            <mat-error *ngIf="email.invalid">{{getEmailErrorMessage()}}</mat-error>
          </mat-form-field>
          <mat-form-field class="form-field">
            <mat-label>Enter your Password</mat-label>
            <input matInput type="password" [(ngModel)]="admin.password" [formControl]="password" required>
            <mat-error *ngIf="password.invalid">{{getPasswordErrorMessage()}}</mat-error>
          </mat-form-field>
            <button mat-raised-button class="login-btn" [disabled]="email.invalid || password.invalid"
            (click)="onSignin(loginForm, email.value,password.value)">Login</button>
          </form>
          <div class="error-message" *ngIf="errorMessage">{{errorMessage}}</div>
          <div class="error-message" *ngIf="startAdminValidation && 
          (firebaseService.isAuthorized === 'not authorized')">Not authorized</div>
    </div>
   </div>   
  
         
   
