import { Injectable } from '@angular/core';
import {CanActivate, Router} from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class NotAuthGuardService  implements CanActivate {

  constructor(public router: Router) { }

  canActivate(): boolean {
    if (localStorage.getItem('user')) {
        this.router.navigate(['/home/cash-register']);

      return false;
    }
    return true;
  }

}
