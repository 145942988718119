import {Injectable} from '@angular/core';
import * as $ from 'jquery';
import {Platform} from '@ionic/angular';

@Injectable({
    providedIn: 'root'
})
export class FirebaseFunctionsService {

    private elasticAuth: any = '';
    private elasticUrl = 'https://6784443225a14957bdb42e97a0e70c29.us-east-1.aws.found.io:9243';
    private apiUrl = 'https://admin.placetta.com.dz/api/';
    //private apiUrl = 'http://localhost:8000/api/';


    constructor(private platform: Platform) {
         this.elasticAuth = 'Placetta #Placetta2021*';

    }

    public getStoreById(store:any) {
        return $.ajax({
            url: this.apiUrl + 'stores/' + store.id,
            type: 'GET',
            contentType: 'application/json; charset=utf-8',
            headers: {
                'Authorization': this.elasticAuth,
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Headers': '*',
                'Accept': 'application/json, text/plain'
            }
        });
    }

    public getStoreByPhone(phone:any) {
        return $.ajax({
            url: this.apiUrl + 'stores/' + 1 + '?byphone=true&phone=' + phone,
            type: 'GET',
            contentType: 'application/json; charset=utf-8',
            headers: {
                'Authorization': this.elasticAuth,
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Headers': '*',
                'Accept': 'application/json, text/plain'
            }
        });
    }

    public getProductById(product:any) {
        return $.ajax({
            url: this.apiUrl + 'products/' + product.id,
            type: 'GET',
            contentType: 'application/json; charset=utf-8',
            headers: {
                'Authorization': this.elasticAuth,
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Headers': '*',
                'Accept': 'application/json, text/plain'
            }
        });
    }

    public getUserByPhone(phone:any) {
        return $.ajax({
            url: this.apiUrl + 'users/mobile/' + phone,
            type: 'POST',
            contentType: 'application/json; charset=utf-8',
            headers: {
                'Authorization': this.elasticAuth,
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Headers': '*',
                'Accept': 'application/json, text/plain'
            }
        });
    }

    public getUserByEmail(email:any) {
        return $.ajax({
            url: this.apiUrl + 'users/email/' + email,
            type: 'POST',
            contentType: 'application/json; charset=utf-8',
            headers: {
                'Authorization': this.elasticAuth,
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Headers': '*',
                'Accept': 'application/json, text/plain'
            }
        });
    }

    public searchStores(options:any) {
        console.log('filter query stores : ', options);
        return $.ajax({
            url: this.apiUrl + 'stores?page=' + options.nextStorePage,
            type: 'GET',
            contentType: 'application/json; charset=utf-8',
            headers: {
                'Authorization': this.elasticAuth,
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Headers': '*',
                'Accept': 'application/json, text/plain'
            },
            data: options
        });
    }

    public searchProducts(options:any) {
        return $.ajax({
            url: this.apiUrl + 'products?page=' + options.nextProductPage,
            type: 'GET',
            contentType: 'application/json; charset=utf-8',
            headers: {
                'Authorization': this.elasticAuth,
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Headers': '*',
                'Accept': 'application/json, text/plain'
            },
            data: options
        });
    }

    public getProductsByStoreId(options:any) {
        const query: any = {
            keyword: options.keyword.toLowerCase()
        };

        console.log('query detail magasin : ', query);

        return $.ajax({
            url: this.apiUrl + 'products/store/' + options.magasinId + '?page=' + options.nextProductPage,
            type: 'GET',
            contentType: 'application/json; charset=utf-8',
            headers: {
                'Authorization': this.elasticAuth,
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Headers': '*',
                'Accept': 'application/json, text/plain'
            },
            data: JSON.stringify(query)
        });
    }

    public createCustomer(customer:any) {
        if (customer.createdAt) {
            const createdAt = new Date(customer.createdAt);
            customer.createdAtTime = createdAt.getTime();
        }
        if (customer.picture && customer.picture.changingThisBreaksApplicationSecurity) {
            customer.picture = customer.picture.changingThisBreaksApplicationSecurity
                .substring(5, customer.picture.changingThisBreaksApplicationSecurity.length - 2);
        }

        return $.ajax({
            url: this.apiUrl + 'users',
            type: 'POST',
            contentType: 'application/json; charset=utf-8',
            headers: {
                'Authorization': this.elasticAuth,
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Headers': '*',
                'Accept': 'application/json, text/plain'
            },
            data: JSON.stringify(customer)
        });
    }





    public updateUser(user:any) {
        if (user.created_at) {
            delete user.created_at;
        }

        if (user.updated_at) {
            delete user.updated_at;
        }
        return $.ajax({
            url: this.apiUrl + 'users/' + user.id,
            type: 'PUT',
            contentType: 'application/json; charset=utf-8',
            headers: {
                'Authorization': this.elasticAuth,
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Headers': '*',
                'Accept': 'application/json, text/plain'
            },
            data: JSON.stringify(user)
        });
    }

    public getAdminStores(page:any, length:any, keyword:any) {

        const query: any = {
            length: length,
            keyword: keyword,
            admin: 1
        };
        return $.ajax({
            url: this.apiUrl + 'stores?page=' + page,
            type: 'GET',
            contentType: 'application/json; charset=utf-8',
            headers: {
                'Authorization': this.elasticAuth,
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Headers': '*',
                'Accept': 'application/json, text/plain'
            },
            data: query
        });
    }

    public createAdminStore(store:any) {
        if (store.pictureUrlReserve) {
            if (store.pictureUrlReserve.indexOf('url(') !== -1) {
                store.pictureUrlReserve = store.pictureUrlReserve
                    .substring(store.pictureUrlReserve.indexOf('(') + 1, store.pictureUrlReserve.indexOf(')'));
                store.picture = store.pictureUrlReserve;
            } else {
                store.picture = store.pictureUrlReserve;
            }
        }
        if (store.createdAt) {
            const createdAt = new Date(store.createdAt);
            store.createdAtTime = createdAt.getTime();
        }
        return $.ajax({
            url: this.apiUrl + 'stores',
            type: 'POST',
            contentType: 'application/json; charset=utf-8',
            headers: {
                'Authorization': this.elasticAuth,
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Headers': '*',
                'Accept': 'application/json, text/plain'
            },
            data: JSON.stringify(store)
        });

    }

    public updateStore(store:any) {
        if (store.created_at) {
            delete store.created_at;
        }

        if (store.updated_at) {
            delete store.updated_at;
        }
        return $.ajax({
            url: this.apiUrl + 'stores/' + store.id,
            type: 'PUT',
            contentType: 'application/json; charset=utf-8',
            headers: {
                'Authorization': this.elasticAuth,
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Headers': '*',
                'Accept': 'application/json, text/plain'
            },
            data: JSON.stringify(store)
        });
    }



    public getAdminStoreById(store:any) {
        return $.ajax({
            url: this.apiUrl + 'stores/' + store.id,
            type: 'GET',
            contentType: 'application/json; charset=utf-8',
            headers: {
                'Authorization': this.elasticAuth,
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Headers': '*',
                'Accept': 'application/json, text/plain'
            }
        });
    }

    public createOrder(order:any) {
        if (order.createdAt) {
            const createdAt = new Date(order.createdAt);
            order.createdAtTime = createdAt.getTime();
        }
        if (order.products) {
            for (const product of order.products) {
                if (product.cardProperties) {
                    for (const property of product.cardProperties) {
                        if (property.values) {
                            for (const propertyValue of property.values) {
                                if (propertyValue.subProperties) {
                                    for (const key of Object.keys(propertyValue.subProperties)) {
                                        if (propertyValue.subProperties[key].values) {
                                            for (const subPropertyValue of propertyValue.subProperties[key].values) {
                                                subPropertyValue.quantity = parseInt(subPropertyValue.quantity, 10);
                                            }
                                        }
                                    }
                                } else {
                                    propertyValue.quantity = parseInt(propertyValue.quantity, 10);
                                }
                            }
                        }
                    }
                }
                if (product.properties) {
                    for (const property of product.properties) {
                        if (property.values) {
                            for (const propertyValue of property.values) {
                                if (propertyValue.subProperties) {
                                    for (const key of Object.keys(propertyValue.subProperties)) {
                                        if (propertyValue.subProperties[key].values) {
                                            for (const subPropertyValue of propertyValue.subProperties[key].values) {
                                                subPropertyValue.quantity = parseInt(subPropertyValue.quantity, 10);
                                            }
                                        }
                                    }
                                } else {
                                    propertyValue.quantity = parseInt(propertyValue.quantity, 10);
                                }
                            }
                        }
                    }
                }
            }
        }
        return $.ajax({
            url: this.apiUrl + 'orders/migrate',
            type: 'POST',
            contentType: 'application/json; charset=utf-8',
            headers: {
                'Authorization': this.elasticAuth,
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Headers': '*',
                'Accept': 'application/json, text/plain'
            },
            data: JSON.stringify(order)
        });

    }



    public createAdminProduct(product:any) {
        if (product.properties) {
            for (const property of product.properties) {
                if (property.values) {
                    for (const propertyValue of property.values) {
                        if (propertyValue.subProperties) {
                            for (const key of Object.keys(propertyValue.subProperties)) {
                                if (propertyValue.subProperties[key].values) {
                                    for (const subPropertyValue of propertyValue.subProperties[key].values) {
                                        subPropertyValue.quantity = parseInt(subPropertyValue.quantity, 10);
                                    }
                                }
                            }
                        } else {
                            propertyValue.quantity = parseInt(propertyValue.quantity, 10);
                        }
                    }
                }
            }
        }
        return $.ajax({
            url: this.apiUrl + 'products',
            type: 'POST',
            contentType: 'application/json; charset=utf-8',
            headers: {
                'Authorization': this.elasticAuth,
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Headers': '*',
                'Accept': 'application/json, text/plain'
            },
            data: JSON.stringify(product)
        });

    }

    public createBookmarkedStore(bookmark:any) {
        return $.ajax({
            url: this.apiUrl + 'bookmarkedStores',
            type: 'POST',
            contentType: 'application/json; charset=utf-8',
            headers: {
                'Authorization': this.elasticAuth,
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Headers': '*',
                'Accept': 'application/json, text/plain'
            },
            data: JSON.stringify(bookmark)
        });
    }

    public createBookmarkedProduct(bookmark:any) {
        return $.ajax({
            url: this.apiUrl + 'bookmarkedProducts',
            type: 'POST',
            contentType: 'application/json; charset=utf-8',
            headers: {
                'Authorization': this.elasticAuth,
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Headers': '*',
                'Accept': 'application/json, text/plain'
            },
            data: JSON.stringify(bookmark)
        });
    }

    public createCard(card:any) {
        return $.ajax({
            url: this.apiUrl + 'cards',
            type: 'POST',
            contentType: 'application/json; charset=utf-8',
            headers: {
                'Authorization': this.elasticAuth,
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Headers': '*',
                'Accept': 'application/json, text/plain'
            },
            data: JSON.stringify(card)
        });
    }

}
