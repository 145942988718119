import { Component, OnInit } from '@angular/core';
import { AuthService } from '../shared/auth.service';
import {FormControl, Validators} from '@angular/forms';
export namespace ErroAuthEn {
  export function convertMessage(code: string): string {
      switch (code) {
          case 'auth/user-disabled': {
              return 'Sorry your user is disabled.';
          }
          case 'auth/user-not-found': {
              return 'Sorry user not found.';
          }
          case 'auth/network-request-failed': {
            return 'network error';
        }
          case 'auth/wrong-password': {
            return 'wrong password';
          }
          default: {
              return 'Login error try again later.';
          }
      }
  }
}
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  public admin = {
    email : '',
    password: '',
  }
  public errorMessage: string;
  public startAdminValidation: boolean;
  constructor(public firebaseService : AuthService) { }

  email = new FormControl('', [Validators.required, Validators.email]);
  password = new FormControl('', [Validators.required, Validators.minLength(8)]);
  getEmailErrorMessage() {
    if (this.email.hasError('required')) {
      return 'email is required';
    }

    return this.email.hasError('email') ? 'invalid email' : '';
  }
  getPasswordErrorMessage() {
    if (this.password.hasError('required')) {
      return 'password is required';
    }

    return this.password.hasError('minlength') ? 'minimum 8 characters' : '';
  }


  ngOnInit(): void {
    
  }
  async onSignin(form: any,email:string,password:string){
    this.errorMessage = '';
    await this.firebaseService.signin(form,email,password).catch(err=>{
      this.errorMessage = ErroAuthEn.convertMessage(err.code);
    });
    if(this.errorMessage && this.errorMessage != '')
    this.startAdminValidation = false;
    else
    this.startAdminValidation = true;

  }
}

