import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { Router } from '@angular/router';
import firebase from 'firebase/app';
import { Observable, Subject, timer } from 'rxjs';
import { User } from '../models/User';
import { FirebaseFunctionsService } from './firebase-functions.service';
@Injectable({
  providedIn: 'root'
})
export class AuthService {
  public loginErrorListener = new Subject<any>();
  public userType: string;

  constructor(public firebaseAuth : AngularFireAuth, private router: Router,
    public firebaseFunctionsService: FirebaseFunctionsService) { }

  public getLoginErrorSubject(): Observable<any> {
    return this.loginErrorListener.asObservable();
}

  async signin(form: any, email: string, password : string){
    await this.firebaseAuth.signInWithEmailAndPassword(email,password)
    .then(res=>{
      console.log('logged in user result : ', res.user);
      password = '';
      this.getUserByEmail(email);
      form.reset();
    })
  }
  public getUserByEmail(email: any) {
    const that = this;
    this.firebaseFunctionsService.getUserByEmail(email)
        .done(data => {
            console.log('got user from db : ', data);
            let user: User = new User();
            user = data;
            this.userType = data.accountType;
            if(user.accountType && (user.accountType === 'admin' || user.accountType === 'commercial' ))
            {
              that.setAuthUser(user);
            }
        })
        .fail(error => {
            console.error('error getting user by email : ', error);
        });
  }
public setAuthUser(user: User) {
    user.password = '';
    localStorage.setItem('user', JSON.stringify(user));
    this.router.navigate(['/home/cash-register']);
}
  async signup(email: string, password : string){
    await this.firebaseAuth.createUserWithEmailAndPassword(email,password)
    .then(res=>{
      localStorage.setItem('user',JSON.stringify(res.user))
    })
  }
  logout(){
    this.firebaseAuth.signOut()
    localStorage.removeItem('user');
    this.router.navigate(['/login']);
    //location.reload();
  }

  get isLoggedIn(): boolean {
    const user = JSON.parse(localStorage.getItem('user'));
    return (user !== null) ? true : false;
  }
  get isAuthorized(): string {
    if(this.userType){
      if(this.userType === 'admin' || this.userType === 'commercial'){
        return 'authorized';
      }
      else{ return 'not authorized';
          }
    }
    else{
      return 'not found';
    }
  }




  public setAuthStore(store: any) {
    if (typeof store.categories === 'string') {
        store.categories = JSON.parse(store.categories);
    }
    if (typeof store.payments === 'string') {
        const paiments: any = store.payments;
        store.payments = JSON.parse(paiments);
    }
    if (typeof store.reviews === 'string') {
        const reviews: any = store.reviews;
        store.reviews = JSON.parse(reviews);
    }
    const picture = store.picture;
    if (picture && picture.changingThisBreaksApplicationSecurity) {
        store.picture = store.picture.changingThisBreaksApplicationSecurity
            .substring(5, store.picture.changingThisBreaksApplicationSecurity.length - 2);
    }

   // localStorage.setItem('authenticatedStore', JSON.stringify(store));
}

public setAuthUserWithoutRouting(user: any) {
  //localStorage.setItem('authenticatedUser', JSON.stringify(user));
}
}
