<div class="row m-b10">
    <div class="incomes-container col-md-4 col-sm-6 col-12">
        <div class="card pos-relative">
            <div class="card-icon pos-absolute display-flex-center">
                <mat-icon>monetization_on</mat-icon>
            </div>
            <div class="card-info">
                <p class="card-title">
                    Incomes
                </p>
                <p class="card-length">
                    {{showLoader? '' : incomes}} DA
                        <img src="../../../assets/images/loader-gray.gif" width="25px" *ngIf="showLoader" />
                </p>
            </div>
        </div>
    </div>
    <div class="outcomes-container col-md-4 col-sm-6 col-12">
      <div class="card pos-relative">
          <div class="card-icon pos-absolute display-flex-center">
              <mat-icon>monetization_on</mat-icon>
          </div>
          <div class="card-info">
              <p class="card-title">
                  Outcomes
              </p>
              <p class="card-length">
                  {{showLoader? '' : outcomes}} DA
                      <img src="../../../assets/images/loader-gray.gif" width="25px" *ngIf="showLoader" />
              </p>
          </div>
      </div>
  </div>
  <div class="incomes-container col-md-4 col-sm-6 col-12"
  [ngClass]="{'outcomes-container': (incomes - outcomes) < 0}">
    <div class="card pos-relative">
        <div class="card-icon pos-absolute display-flex-center">
            <mat-icon>monetization_on</mat-icon>
        </div>
        <div class="card-info">
            <p class="card-title">
                Net
            </p>
            <p class="card-length">
                {{((incomes - outcomes) <= 0)? '' : '+'}} {{showLoader? '' : (incomes - outcomes).toFixed(2)}} DA
                    <img src="../../../assets/images/loader-gray.gif" width="25px" *ngIf="showLoader" />
            </p>
        </div>
    </div>
</div>
</div>
<div class="text-right">
    <button mat-raised-button class="add-btn" (click)="openAddFundsForm()">
        <ion-icon name="add"></ion-icon>
         ADD</button>
</div>
  <div class="table-container">
    
  <table mat-table [dataSource]="dataSource" multiTemplateDataRows matSort
  (matSortChange)="sortChange()">
  <ng-container matColumnDef="{{column}}" *ngFor="let column of displayedColumns">
  <th mat-header-cell *matHeaderCellDef
  [mat-sort-header]=" (column!='user') ? column : null "
      [disabled]=" (column=='user') ? true : false ">
   {{column}}
  </th>
    <ng-container *ngIf="!showLoader">
      <td mat-cell *matCellDef="let element"
      [ngClass]="{'income-td-color': (column == 'amount' && element['type'] == 'income'),
      'outcome-td-color': (column == 'amount' && element['type'] == 'outcome')}"> 
        {{(column == 'amount' && element['type'] == 'income')? '+' 
        : (column == 'amount' && element['type'] == 'outcome')? '-' : ''}}
         {{column == 'amount'? element[column] + ' DA': element[column]}}
        </td>
    </ng-container>
    
  </ng-container>
  <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
  <ng-container matColumnDef="expandedDetail">
  <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">
  <div class="customer-picture-container"
      [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
    <div class="container">
        <div class="row">
          <div class="col-12">
            <p>
              <strong>
                  Notes :
              </strong> {{element.note}}</p>
          </div>
          <div class="col-md-6 col-12">
            <p>
              <strong>
                  type :
              </strong> {{element.type}}</p>
          </div>
          <div class="col-md-6 col-12" *ngIf="element.currency != 'DA'">
            <p>
              <strong>
                  amount ({{element.currency}}) :
              </strong> {{element.amount/element.currency_base}} {{element.currency}}</p>
          </div>
          <div class="col-md-6 col-12" *ngIf="element.currency != 'DA'">
            <p>
              <strong>
                  currency :
              </strong> {{element.currency}}</p>
          </div>
          <div class="col-md-6 col-12" *ngIf="element.currency != 'DA'">
            <p>
              <strong>
                   base :
              </strong> 1 {{element.currency}} = {{element.currency_base}} DA</p>
          </div>
        </div>
          
      </div>
  </div>
  </td>
  </ng-container>
  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let element; columns: displayedColumns;"
    class="element-row"
    [class.expanded-row]="expandedElement === element"
    (click)="expandedElement = expandedElement === element ? null : element">
    </tr>
    <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="detail-row"></tr>
  <!-- Row shown when there is no matching data. -->
  <ng-container *ngIf="!showLoader">
    <tr class="mat-row" *matNoDataRow>
      <td class="mat-cell full-width" [attr.colspan]="displayedColumns.length"
      *ngIf="!showLoader">No data found</td>
      </tr>
  </ng-container>
  </table>
    <div class="mat-cell loader-section ion-text-center full-width" *ngIf="showLoader">
      <img src="../../../assets/images/loader-gray.gif"/>
    </div>
    <mat-paginator [pageSizeOptions]="pageSizeOptions"
    (page)="pageEvent($event)" [length]="length"
    ></mat-paginator>
  </div>