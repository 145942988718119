import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import * as $ from 'jquery';
import { ModalController } from '@ionic/angular';
import { BackendService } from 'src/app/shared/backend.service';
import { LoadingService } from 'src/app/shared/loading.service';
@Component({
  selector: 'app-cash-register-form',
  templateUrl: './cash-register-form.component.html',
  styleUrls: ['./cash-register-form.component.scss']
})
export class CashRegisterFormComponent implements OnInit {

  public showLoader: boolean;
  public user: any;
  operationControl = new FormControl();
  amountControl = new FormControl();
  rateControl = new FormControl();
  noteControl = new FormControl();
  operations: string[] = ['One', 'Two', 'Three'];
  filteredOperations: Observable<string[]>;
  public currencies: string[] = ['DA','EUR','SAR'];
  public selectedCurrency: string;
  public validationText: string;
  public selectedType: string;
  constructor(public modalController: ModalController,
    public backendService: BackendService,public loadingService: LoadingService) { }

  ngOnInit(): void {
    if (localStorage.getItem('user')) {
      this.user = JSON.parse(localStorage.getItem('user'));
    }
    this.selectedCurrency = 'DA';
    this.filteredOperations = this.operationControl.valueChanges.pipe(
      startWith(''),
      map(value => this._filterOperations(value)),
    );
  }

  private _filterOperations(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.operations.filter(option => option.toLowerCase().includes(filterValue));
  }
  public getSelectedValue(event:any){
    console.log(this.selectedCurrency);
    
  }

  public ValidateType() {
    if (this.selectedType && this.selectedType != '') {
      this.validationText = 'valid';
      return true;
    }
    else {
      this.validationText = 'typeRequired';
      return false;
    }
  }
  public ValidateOperation() {
    if (this.operationControl.value && this.operationControl.value != '') {
      this.validationText = 'valid';
      return true;
    }
    else {
      this.validationText = 'operationRequired';
      return false;
    }
  }
  public ValidateAmount() {
    if (this.amountControl.value && this.amountControl.value != 0) {
      this.validationText = 'valid';
      return true;
    }
    else {
      this.validationText = 'amountRequired';
      return false;
    }
  }
  public ValidateRate() {
    if(this.selectedCurrency == 'DA'){
      return true;
    }
    else{
      if (this.rateControl.value && this.rateControl.value != 0) {
        this.validationText = 'valid';
        return true;
      }
      else {
        this.validationText = 'rateRequired';
        return false;
      }
    }
  }
  public save(form: any){
    if(this.ValidateType() && this.ValidateOperation() && this.ValidateAmount() && this.ValidateRate()){
      const operation: any = {};
      operation.user_id = this.user.id;
      operation.label = this.operationControl.value;
      operation.amount = this.selectedCurrency == 'DA'? this.amountControl.value : this.amountControl.value*this.rateControl.value;
      operation.type = this.selectedType;
      operation.currency = this.selectedCurrency;
      operation.currency_base = this.selectedCurrency == 'DA'? 1 : this.rateControl.value;
      operation.note = this.noteControl.value;
      this.loadingService.showLoading();
      this.showLoader = true;
      this.backendService.createNewOperation(operation).done(()=>{
        this.loadingService.hideLoading();
        this.showLoader = false;
        console.log('operation created succcessfully');
        $('.message-sent-success').fadeIn(300);
       form.reset();
       window.setTimeout(() => {
         $('.message-sent-success').fadeOut(300);
       } , 1000);
      }).fail(err=>{
        this.loadingService.hideLoading();
        this.showLoader = false;
        console.error(err);
        $('.message-sent-fail').fadeIn(300);
       form.reset();
       window.setTimeout(() => {
         $('.message-sent-fail').fadeOut(300);
       } , 1000);
      })

    }
    else{
      console.log('error : invalid form');
      
    }
  }
  public close(){
    this.modalController.dismiss();
  }
}
