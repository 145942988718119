import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './components/home/home.component';
import { LoginComponent } from './login/login.component';
import { AuthGuardService } from './shared/auth-guard.service';
import { NotAuthGuardService } from './shared/not-auth-guard.service';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/home/cash-register',
    pathMatch: 'full'
  },
  {path: 'login', component: LoginComponent,
canActivate: [NotAuthGuardService]},
  // {path: 'home/payments', component: HomeComponent,
  // canActivate: [AuthGuardService]},
  {path: 'home/cash-register', component: HomeComponent,
  canActivate: [AuthGuardService]},
/**
  {path: 'settings', component: SettingsComponent},
 *
 */
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
