<mat-sidenav-container class="full-height" [hasBackdrop]="hasBackdrop" autosize>
    <mat-sidenav #sidenav  class="sidenav deep-purple-gradient" [mode]="mode" *ngIf="this.firebaseService.isLoggedIn">
        <div class="logo">
             Placetta
    </div>
          <ul class="list-items">
            <!-- <li class="item"
                (click)="goTo('payments')" [ngClass]="{'active': activeItem === 'payments'}">

            <b></b>
                   <mat-icon>description</mat-icon>
                    Payments
            </li> -->
            <li class="item" *ngIf="user.accountType != 'commercial'"
                (click)="goTo('cash-register')" [ngClass]="{'active': activeItem === 'cash-register'}">
            <b></b>
            <ion-icon name="cash"></ion-icon>
                    Cash Register
            </li>
        </ul>
    </mat-sidenav>
        <mat-sidenav-content *ngIf="this.firebaseService.isLoggedIn">
            <nav class="navbar display-flex-end">
                <div class="avatar display-flex-center full-height">
                    <img src="../../../assets/images/avatar.webp"
                 [matMenuTriggerFor]="menu" />
                </div>
        <mat-icon class="open-sidenav" (click)="toggleSideNav()">menu</mat-icon>
              </nav>
                <mat-menu #menu="matMenu">
                  <button mat-menu-item class="outline-none" (click)="logout()">
                    <mat-icon>logout</mat-icon>
                    <span>logout</span>
                  </button>
                </mat-menu>
                <!-- <h2 class="title">{{activeItem}}</h2>-->
            <div class="container-fluid">
                <div class="row">
                    <div class="col-12">
                        <!-- <app-payments *ngIf="activeItem === 'payments'"></app-payments> -->
                        <app-cash-register *ngIf="activeItem === 'cash-register' && user.accountType != 'commercial'"></app-cash-register>
                    </div>
                </div>
            </div>
        </mat-sidenav-content>
        <mat-sidenav-content *ngIf="!this.firebaseService.isLoggedIn">
                        <app-login></app-login>
        </mat-sidenav-content>
</mat-sidenav-container>
