import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {MatPaginator, PageEvent} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import { DomSanitizer } from '@angular/platform-browser';
import { BackendService } from 'src/app/shared/backend.service';
import {animate, state, style, transition, trigger} from '@angular/animations';
import { User } from 'src/app/models/User';
import { LoadingService } from 'src/app/shared/loading.service';
import { ActionSheetController, ModalController } from '@ionic/angular';
import * as XLSX from 'xlsx';
import { CashRegisterLine } from 'src/app/models/CashRegisterLine';
import { CashRegisterFormComponent } from 'src/app/modals/cash-register-form/cash-register-form.component';

 @Component({
  selector: 'app-cash-register',
  templateUrl: './cash-register.component.html',
  styleUrls: ['./cash-register.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class CashRegisterComponent implements AfterViewInit, OnInit {
 // displayedColumns: string[] = ['id', 'name', 'progress', 'fruit'];
  displayedColumns: string[] = ['id', 'user_id','user', 'label', 'amount', 'created_at'];
  expandedElement: CashRegisterLine | null;
  dataSource: MatTableDataSource<CashRegisterLine>;
  filteredItems: any[] = [];
  public itemsToExport: any[] = [];
  public showLoader: boolean;
  public incomes: any;
  public outcomes: any;
  public user: User;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  pageSizeOptions: number[] = [10];
  length: any;
  pageIndex: number;
  keyword: string;
  constructor(private backendService: BackendService, public sanitizer: DomSanitizer,
    private loadingService: LoadingService,
    public actionSheetController: ActionSheetController,
    public modalController: ModalController) {
    
  }
  ngOnInit(): void {
    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    //Add 'implements OnInit' to the class.
    this.incomes = 0;
    this.outcomes = 0;
    this.pageIndex = 1;
    this.keyword='';
    this.getItems();      

    // Assign the data to the data source for the table to render
    this.dataSource = new MatTableDataSource(this.filteredItems);    
  }
  initAdmin(id){
    this.backendService.getUserById(id).done(admin=> this.user = admin)
    .fail(err=>console.error(err));

    return this.user.fullName;
  }
  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
   // this.dataSource.sort = this.sort;
  }

  /**
   * (matSortChange)="sortChange($event)"
   */
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.filteredItems = [];
    this.dataSource = new MatTableDataSource(this.filteredItems);
    this.keyword = filterValue;
    this.pageIndex = 1;
    this.paginator.pageIndex = 0;
    this.sortChange();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
  public getItems() {
    this.filteredItems = [];
    this.loadingService.showLoading();
    const today = new Date();
    this.getPaginatedItems();
  }
  getPaginatedItems(options = {}){
    const that = this;
    this.filteredItems = [];
    this.dataSource = new MatTableDataSource(this.filteredItems);
    this.showLoader = true;
    this.backendService.getCashRegister(that.pageIndex, options).done(data => {
    this.filteredItems= [];
    this.dataSource = new MatTableDataSource(this.filteredItems);
    this.incomes = data[1][0].incomes? data[1][0].incomes : 0;
    this.outcomes = data[2][0].outcomes? data[2][0].outcomes : 0;
      const results = data[0].data;
      this.length = data[0].total;
      console.log(data);
      if (results.length > 0) {
        for (const item of results) {
          const today = new Date();
          const createdAt = new Date(item.created_at);
    let date = ("0" + createdAt.getDate()).slice(-2);
    let month = ("0" + (createdAt.getMonth()+1)).slice(-2);
    let year = createdAt.getFullYear();
    let hours = ("0" + createdAt.getHours()).slice(-2);
    let minutes = ("0" + createdAt.getMinutes()).slice(-2);
    let seconds = ("0" + createdAt.getSeconds()).slice(-2);
    item.created_at = year + "-" + month + "-" + date + " " + hours + ":" + minutes + ":" + seconds;
    
          if (!item.createdAt) {
            today.setFullYear(today.getFullYear() - 1);
            item.createdAt = today;
          }
          if(item.user) item.user = item.user.fullName;
          that.filteredItems.push(item);
        }
      }
      this.dataSource = new MatTableDataSource(this.filteredItems);
    this.dataSource.filter = this.keyword.trim().toLowerCase();
   // this.dataSource.sort = this.sort;

        console.log(this.filteredItems);
      this.showLoader = false;
      this.loadingService.hideLoading();
    })
    .fail(err => {
      console.error('error getting customers !!! ' , err);
      this.loadingService.hideLoading();
    });
    
  }

  async openAddFundsForm() {
    const modal = await this.modalController.create({
       component: CashRegisterFormComponent,
       backdropDismiss: false,
       cssClass: 'add-funds-operation'
   });
   modal.onDidDismiss()
   .then((data) => {
     this.getPaginatedItems();
   }
   );
   return await modal.present();
}
  
  public sortChange(){
    console.log(this.sort.active, this.sort.direction);
    if(this.sort.direction){
      const options: any = {};
      options.sortColumn = this.sort.active;
      options.sortType = this.sort.direction;
      this.getPaginatedItems(options);
        
    }
    else{
      const options: any = {};
        this.getPaginatedItems(options);
    }
  }
  pageEvent(event: PageEvent){
    this.filteredItems = [];
    this.dataSource = new MatTableDataSource(this.filteredItems);
  this.pageIndex = event.pageIndex+1;
  this.showLoader = true;
  this.sortChange();
  }
  
}




/**
 * import { Component, OnInit } from '@angular/core';
import { BackendService } from 'src/app/shared/backend.service';

@Component({
  selector: 'app-customers',
  templateUrl: './customers.component.html',
  styleUrls: ['./customers.component.scss']
})
export class CustomersComponent implements OnInit {
  displayedColumns: string[] = ['position', 'name', 'id', 'wilaya', 'phone', 'date', 'action'];
  dataSource: any[] = [{}];
  constructor( private backendService: BackendService) { }

  ngOnInit(): void {
  }
  getCustomers(){
 }

 public confirm(){

   }


}

 */