import { Injectable } from '@angular/core';
import * as $ from 'jquery';
import { Subject } from 'rxjs';
import { Coupon } from '../models/Coupon';
import { Order } from '../models/Order';
import { Product } from '../models/Product';
import { PublishingHouse } from '../models/publishingHouse';
import { Store } from '../models/Store';
import { User } from '../models/User';
import { LoadingService } from './loading.service';
@Injectable({
  providedIn: 'root'
})
export class BackendService {

  public storeOrdersChanged = new Subject<any>();
    public storeSalesChanged = new Subject<any>();


  private elasticAuth: any = 'Basic ' + btoa('elastic:CUTmifvShcDUGgDs6Vw7oHQv');
  // private elasticUrl = 'https://6784443225a14957bdb42e97a0e70c29.us-east-1.aws.found.io:9243';
  private apiUrl = 'https://admin.placetta.com.dz/api/';
  //private maystroUrl = 'https://placetta-backend-dhe4gx7xza-ew.a.run.app/maystro/';
  private maystroUrl = 'https://admin.placetta.com.dz/maystro/';
  //private apiUrl = 'http://localhost:8000/api/';
  constructor(public loadingService:LoadingService) {
    this.elasticAuth = 'Placetta #Placetta2021*';
  }
public getPaginatedCustomers(page: number,keyword:string, options = {}) {
    return $.ajax({
        //url: this.apiUrl+'all-users',
        url: this.apiUrl+'users?page='+page+ '&keyword=' + keyword,
        type: 'GET',
        contentType: 'application/json; charset=utf-8',
        headers: {
          'Authorization': this.elasticAuth,
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Headers': '*',
            'Accept': 'application/json, text/plain'
        },
        data: options
    });

}

public getCustomersToExport(keyword:string, options = {}) {
  return $.ajax({
      url: this.apiUrl+'users?keyword=' + keyword,
      type: 'GET',
      contentType: 'application/json; charset=utf-8',
      headers: {
        'Authorization': this.elasticAuth,
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Headers': '*',
          'Accept': 'application/json, text/plain'
      },
      data: options
  });

}

public deleteUser(user: User) {
  return $.ajax({
      url: this.apiUrl + 'users/' + user.id,
      type: 'DELETE',
      contentType: 'application/json; charset=utf-8',
      headers: {
          'Authorization': this.elasticAuth,
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Headers': '*',
          'Accept': 'application/json, text/plain'
      },
      data: JSON.stringify(user)
  });
}
public deleteOrder(order: Order) {
    return $.ajax({
        url: this.apiUrl + 'orders/' + order.id,
        type: 'DELETE',
        contentType: 'application/json; charset=utf-8',
        headers: {
            'Authorization': this.elasticAuth,
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Headers': '*',
            'Accept': 'application/json, text/plain'
        },
        data: JSON.stringify(order)
    });
  }

public getAdminStores(page: number, keyword: string,
   column: string, type: string, filterType: string, storeValidation: string,
    storeStatus: string) {
  const query: any = {
      keyword: keyword,
      sortColumn: column,
      sortType: type,
      admin: true,
      filterType: filterType,
      storeValidation: storeValidation,
      storeStatus: storeStatus
  };
  return $.ajax({
      url: this.apiUrl + 'stores?page=' + page,
      type: 'GET',
      contentType: 'application/json; charset=utf-8',
      headers: {
          'Authorization': this.elasticAuth,
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Headers': '*',
          'Accept': 'application/json, text/plain'
      },
      data: query
  });
}
public getStoresForDeliveryType(page: number, keyword: string,
  column: string, type: string, filterType: string, storeValidation: string,
   storeStatus: string, isFreeDelivery: number) {
 const query: any = {
     keyword: keyword,
     sortColumn: column,
     sortType: type,
     admin: true,
     filterType: filterType,
     storeValidation: storeValidation,
     storeStatus: storeStatus,
     isFreeDelivery: isFreeDelivery
 };
 return $.ajax({
     url: this.apiUrl + 'stores?page=' + page,
     type: 'GET',
     contentType: 'application/json; charset=utf-8',
     headers: {
         'Authorization': this.elasticAuth,
         'Access-Control-Allow-Origin': '*',
         'Access-Control-Allow-Headers': '*',
         'Accept': 'application/json, text/plain'
     },
     data: query
 });
}

public getStoresToExport( keyword: string, column: string, type: string,
   filterType: string, storeValidation: string, storeStatus: string) {
  const query: any = {
      keyword: keyword,
      sortColumn: column,
      sortType: type,
      admin: true,
      filterType: filterType,
      storeValidation: storeValidation,
      storeStatus: storeStatus,
      all: true
  };
  return $.ajax({
      url: this.apiUrl + 'stores',
      type: 'GET',
      contentType: 'application/json; charset=utf-8',
      headers: {
          'Authorization': this.elasticAuth,
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Headers': '*',
          'Accept': 'application/json, text/plain'
      },
      data: query
  });
}
public updateStore(store: Store) {
  return $.ajax({
      url: this.apiUrl + 'stores/' + store.id,
      type: 'PUT',
      contentType: 'application/json; charset=utf-8',
      headers: {
          'Authorization': this.elasticAuth,
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Headers': '*',
          'Accept': 'application/json, text/plain'
      },
      data: JSON.stringify(store)
  });
}

public switchToStore(user: User) {
  return $.ajax({
      url: this.apiUrl + 'stores/switch/' + user.id,
      type: 'POST',
      contentType: 'application/json; charset=utf-8',
      headers: {
          'Authorization': this.elasticAuth,
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Headers': '*',
          'Accept': 'application/json, text/plain'
      },
      //data: JSON.stringify(user)
  });
}

public deleteStore(store: Store) {
  return $.ajax({
      url: this.apiUrl + 'stores/' + store.id,
      type: 'DELETE',
      contentType: 'application/json; charset=utf-8',
      headers: {
          'Authorization': this.elasticAuth,
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Headers': '*',
          'Accept': 'application/json, text/plain'
      },
      data: JSON.stringify(store)
  });
}
public getProductsByStoreId(options: any) {

  if (!options.keyword) {
      options.keyword = '';
  }
  const query: any = {
      keyword: options.keyword.toLowerCase()
  };

  if (options.allProducts) {
      query.allProducts = true;
  }

  console.log('query detail magasin : ', query);

  return $.ajax({
      url: this.apiUrl + 'products/store/' + options.magasinId + '?page=' + options.nextStorePage,
      type: 'GET',
      contentType: 'application/json; charset=utf-8',
      headers: {
          'Authorization': this.elasticAuth,
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Headers': '*',
          'Accept': 'application/json, text/plain'
      },
      data: query
  });
}

public deleteProduct(product: Product) {
  return $.ajax({
      url: this.apiUrl + 'products/' + product.id,
      type: 'DELETE',
      contentType: 'application/json; charset=utf-8',
      headers: {
          'Authorization': this.elasticAuth,
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Headers': '*',
          'Accept': 'application/json, text/plain'
      },
      data: JSON.stringify(product)
  });
}

public getProductsStatistics() {
  return $.ajax({
      url: this.apiUrl + 'products/all/statistics',
      type: 'GET',
      contentType: 'application/json; charset=utf-8',
      headers: {
          'Authorization': this.elasticAuth,
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Headers': '*',
          'Accept': 'application/json, text/plain'
      }
  });
}

public getAllProducts(page: number, keyword: string, options = {}) {
  return $.ajax({
      url: this.apiUrl + 'products/admin/list?page=' + page + '&keyword=' + keyword,
      type: 'GET',
      contentType: 'application/json; charset=utf-8',
      headers: {
          'Authorization': this.elasticAuth,
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Headers': '*',
          'Accept': 'application/json, text/plain'
      },
      data: options
  });
}

public getListOfProducts(idList) {
  const request = {
      id_list: idList
  };
  return $.ajax({
      url: this.apiUrl + 'products-by-id-list',
      type: 'GET',
      contentType: 'application/json; charset=utf-8',
      headers: {
          'Authorization': this.elasticAuth,
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Headers': '*',
          'Accept': 'application/json, text/plain'
      },
      data: request
  });
}

public updateProduct(product: Product) {
  return $.ajax({
      url: this.apiUrl + 'products/' + product.id,
      type: 'PUT',
      contentType: 'application/json; charset=utf-8',
      headers: {
          'Authorization': this.elasticAuth,
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Headers': '*',
          'Accept': 'application/json, text/plain'
      },
      data: JSON.stringify(product)
  });
}
public createProduct(product) {
  if (product.properties) {
      for (const property of product.properties) {
          if (property.values) {
              for (const propertyValue of property.values) {
                  if (propertyValue.subProperties) {
                      for (const key of Object.keys(propertyValue.subProperties)) {
                          if (propertyValue.subProperties[key].values) {
                              for (const subPropertyValue of propertyValue.subProperties[key].values) {
                                  subPropertyValue.quantity = parseInt(subPropertyValue.quantity, 10);
                              }
                          }
                      }
                  } else {
                      propertyValue.quantity = parseInt(propertyValue.quantity, 10);
                  }
              }
          }
      }
  }
  return $.ajax({
      url: this.apiUrl + 'products',
      type: 'POST',
      contentType: 'application/json; charset=utf-8',
      headers: {
          'Authorization': this.elasticAuth,
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Headers': '*',
          'Accept': 'application/json, text/plain'
      },
      data: JSON.stringify(product)
  });

}
public getProductsByCategory(category: any) {
  return $.ajax({
      url: this.apiUrl + 'products/category/' + category,
      type: 'GET',
      contentType: 'application/json; charset=utf-8',
      headers: {
          'Authorization': this.elasticAuth,
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Headers': '*',
          'Accept': 'application/json, text/plain'
      },
      data: category
  });
}

public updateCategoryStatus(categories: any, value: any) {
  const options: any = {
      categories: categories,
      value: value
  };

  return $.ajax({
      url: this.apiUrl + 'categories/update-status',
      type: 'POST',
      contentType: 'application/json; charset=utf-8',
      headers: {
          'Authorization': this.elasticAuth,
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Headers': '*',
          'Accept': 'application/json, text/plain'
      },
      data: JSON.stringify(options),
  });
}
public activateFreeDeliveryForProductsByCategory(categories: any) {
  const options: any = {
    categories: categories
};
  return $.ajax({
      url: this.apiUrl + 'products/activate-free-delivery',
      type: 'POST',
      contentType: 'application/json; charset=utf-8',
      headers: {
          'Authorization': this.elasticAuth,
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Headers': '*',
          'Accept': 'application/json, text/plain'
      },
      data: JSON.stringify(options),
  });
}
public deActivateFreeDeliveryForProductsByCategory(categories: any) {
  const options: any = {
    categories: categories
};
  return $.ajax({
      url: this.apiUrl + 'products/deactivate-free-delivery',
      type: 'POST',
      contentType: 'application/json; charset=utf-8',
      headers: {
          'Authorization': this.elasticAuth,
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Headers': '*',
          'Accept': 'application/json, text/plain'
      },
      data: JSON.stringify(options),

  });
}
public getOrders(page: any, keyword: any, options = {}) {
  return $.ajax({
      url: this.apiUrl + 'orders?page=' + page + '&keyword=' + keyword,
      type: 'GET',
      contentType: 'application/json; charset=utf-8',
      headers: {
          'Authorization': this.elasticAuth,
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Headers': '*',
          'Accept': 'application/json, text/plain'
      },
      data: options
  });
}
public getOrdersToExport(keyword: any, options = {}) {
  return $.ajax({
      url: this.apiUrl + 'orders?keyword=' + keyword,
      type: 'GET',
      contentType: 'application/json; charset=utf-8',
      headers: {
          'Authorization': this.elasticAuth,
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Headers': '*',
          'Accept': 'application/json, text/plain'
      },
      data: options
  });
}
public getOrdersStatistics(from: any, to: any) {
  return $.ajax({
      url: this.apiUrl + 'orders/all/statistics?from='+from+'&to='+ to,
      type: 'GET',
      contentType: 'application/json; charset=utf-8',
      headers: {
          'Authorization': this.elasticAuth,
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Headers': '*',
          'Accept': 'application/json, text/plain'
      }
  });
}
public createOrder(order) {
  if (order.createdAt) {
      const createdAt = new Date(order.createdAt);
      order.createdAtTime = createdAt.getTime();
  }
  if (order.products) {
      for (const product of order.products) {
          if (product.properties) {
              for (const property of product.properties) {
                  if (property.values) {
                      for (const propertyValue of property.values) {
                          if (propertyValue.subProperties) {
                              for (const key of Object.keys(propertyValue.subProperties)) {
                                  if (propertyValue.subProperties[key].values) {
                                      for (const subPropertyValue of propertyValue.subProperties[key].values) {
                                          subPropertyValue.quantity = parseInt(subPropertyValue.quantity, 10);
                                      }
                                  }
                              }
                          } else {
                              propertyValue.quantity = parseInt(propertyValue.quantity, 10);
                          }
                      }
                  }
              }
          }
      }
  }
  return $.ajax({
      url: this.apiUrl + 'orders',
      type: 'POST',
      contentType: 'application/json; charset=utf-8',
      headers: {
          'Authorization': this.elasticAuth,
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Headers': '*',
          'Accept': 'application/json, text/plain'
      },
      data: JSON.stringify(order)
  });

}

public createOrderProducts(products) {
  if (products) {
      for (const product of products) {
          if (product.properties) {
              for (const property of product.properties) {
                  if (property.values) {
                      for (const propertyValue of property.values) {
                          if (propertyValue.subProperties) {
                              for (const key of Object.keys(propertyValue.subProperties)) {
                                  if (propertyValue.subProperties[key].values) {
                                      for (const subPropertyValue of propertyValue.subProperties[key].values) {
                                          subPropertyValue.quantity = parseInt(subPropertyValue.quantity, 10);
                                      }
                                  }
                              }
                          } else {
                              propertyValue.quantity = parseInt(propertyValue.quantity, 10);
                          }
                      }
                  }
              }
          }
      }
  }
  return $.ajax({
      url: this.apiUrl + 'order-product/multiple/create',
      type: 'POST',
      contentType: 'application/json; charset=utf-8',
      headers: {
          'Authorization': this.elasticAuth,
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Headers': '*',
          'Accept': 'application/json, text/plain'
      },
      data: JSON.stringify(products)
  });

}
public updateOrderProducts(products) {
  return $.ajax({
      url: this.apiUrl + 'order-product/multiple/update',
      type: 'POST',
      contentType: 'application/json; charset=utf-8',
      headers: {
          'Authorization': this.elasticAuth,
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Headers': '*',
          'Accept': 'application/json, text/plain'
      },
      data: JSON.stringify(products)
  });

}
public deleteOrderProducts(products) {
  return $.ajax({
      url: this.apiUrl + 'order-product/multiple/delete',
      type: 'DELETE',
      contentType: 'application/json; charset=utf-8',
      headers: {
          'Authorization': this.elasticAuth,
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Headers': '*',
          'Accept': 'application/json, text/plain'
      },
      data: JSON.stringify(products)
  });

}
public getPayments(page: any, keyword: any, options = {}) {
  return $.ajax({
      url: this.apiUrl + 'payments?page=' + page + '&keyword=' + keyword,
      type: 'GET',
      contentType: 'application/json; charset=utf-8',
      headers: {
          'Authorization': this.elasticAuth,
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Headers': '*',
          'Accept': 'application/json, text/plain'
      },
      data: options
  });
}
public getPaymentsToExport(keyword: any, options = {}) {
  return $.ajax({
      url: this.apiUrl + 'payments?keyword=' + keyword,
      type: 'GET',
      contentType: 'application/json; charset=utf-8',
      headers: {
          'Authorization': this.elasticAuth,
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Headers': '*',
          'Accept': 'application/json, text/plain'
      },
      data: options
  });
}
public getPaymentsTrack() {
    return $.ajax({
        url: this.apiUrl + 'payments/filter/payments-track',
        type: 'GET',
        contentType: 'application/json; charset=utf-8',
        headers: {
            'Authorization': this.elasticAuth,
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Headers': '*',
            'Accept': 'application/json, text/plain'
        }
    });
  }

  public getWilayas() {
    return $.ajax({
        url: this.apiUrl + 'wilayas',
        type: 'GET',
        contentType: 'application/json; charset=utf-8',
        headers: {
            'Authorization': this.elasticAuth,
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Headers': '*',
            'Accept': 'application/json, text/plain'
        }
    });
}

  public getPaymentsHistory(page: any, keyword: any, options = {}) {
    return $.ajax({
        url: this.apiUrl + 'payments/filter/payments-history?page=' + page + '&keyword=' + keyword,
        type: 'GET',
        contentType: 'application/json; charset=utf-8',
        headers: {
            'Authorization': this.elasticAuth,
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Headers': '*',
            'Accept': 'application/json, text/plain'
        },
        data: options
    });
  }
  public getPaymentsHistoryToExport(keyword: any, options = {}) {
    return $.ajax({
        url: this.apiUrl + 'payments/filter/payments-history?keyword=' + keyword,
        type: 'GET',
        contentType: 'application/json; charset=utf-8',
        headers: {
            'Authorization': this.elasticAuth,
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Headers': '*',
            'Accept': 'application/json, text/plain'
        },
        data: options
    });
  }
public getUnpaidOrders() {
  return $.ajax({
      url: this.apiUrl + 'payments/filter/unpaid-orders',
      type: 'GET',
      contentType: 'application/json; charset=utf-8',
      headers: {
          'Authorization': this.elasticAuth,
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Headers': '*',
          'Accept': 'application/json, text/plain'
      }
  });
}
public getStoreById(store: Store) {
  return $.ajax({
      url: this.apiUrl + 'stores/' + store.id,
      type: 'GET',
      contentType: 'application/json; charset=utf-8',
      headers: {
          'Authorization': this.elasticAuth,
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Headers': '*',
          'Accept': 'application/json, text/plain'
      }
  });
}

public updateOrder(order: Order) {
  return $.ajax({
      url: this.apiUrl + 'orders/' + order.id,
      type: 'PUT',
      contentType: 'application/json; charset=utf-8',
      headers: {
          'Authorization': this.elasticAuth,
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Headers': '*',
          'Accept': 'application/json, text/plain'
      },
      data: JSON.stringify(order)
  });
}

public downloadPdf(params) {
    const request = params;
    return $.ajax({
        url: this.apiUrl + 'orders/invoice',
        type: 'POST',
        contentType: 'application/json; charset=utf-8',
        headers: {
            'Authorization': this.elasticAuth,
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Headers': '*',
            'Accept': 'application/json,application/pdf, text/plain',
             },
        data: JSON.stringify(request),
        xhrFields: {
            responseType: 'blob'
        },
        success: function(response){
            var blob = new Blob([response]);
            const blob1 = window.URL.createObjectURL(blob);
            const anchor = document.createElement('a');
            anchor.style.display = 'none';
            anchor.href = blob1;
            anchor.download = request.invoice_title + ".pdf";
            document.body.appendChild(anchor);
            anchor.click();
            window.URL.revokeObjectURL(blob1);
        }
    });
  }
public cancelMaystroOrder(magasinId: any, orderId: any) {
  return $.ajax({
      url: this.maystroUrl + 'cancel-maystro-order/'+ magasinId +'/'+ orderId,
      type: 'POST',
      contentType: 'application/json; charset=utf-8',
      headers: {
          'Authorization': this.elasticAuth,
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Headers': '*',
          'Accept': 'application/json, text/plain'
      },
     // data: JSON.stringify(order)
  });
}
public fixCommissions(magasinId: any) {
    return $.ajax({
        url: this.apiUrl + 'orders/commissions/'+ magasinId,
        type: 'POST',
        contentType: 'application/json; charset=utf-8',
        headers: {
            'Authorization': this.elasticAuth,
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Headers': '*',
            'Accept': 'application/json, text/plain'
        },
       // data: JSON.stringify(order)
    });
  }

public refreshStatus() {
  return $.ajax({
      url: this.maystroUrl + 'refresh-maystro-order-status',
      type: 'GET',
      contentType: 'application/json; charset=utf-8',
      headers: {
          'Authorization': this.elasticAuth,
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Headers': '*',
          'Accept': 'application/json, text/plain'
      },
     // data: JSON.stringify(order)
  });
}



public getUserById(userId: any) {
  return $.ajax({
      url: this.apiUrl + 'users/' + userId,
      type: 'GET',
      contentType: 'application/json; charset=utf-8',
      headers: {
          'Authorization': this.elasticAuth,
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Headers': '*',
          'Accept': 'application/json, text/plain'
      }
  });
}

public validateProductsByStoreId(magasinId: any , validatedAt: any) {
  const request = {
      validatedAt: validatedAt
  };
  return $.ajax({
      url: this.apiUrl + 'validate-store-products/' + magasinId,
      type: 'POST',
      contentType: 'application/json; charset=utf-8',
      headers: {
          'Authorization': this.elasticAuth,
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Headers': '*',
          'Accept': 'application/json, text/plain'
      },
      data: JSON.stringify(request)
  });
}
public deleteProductsByStoreId(magasinId: any) {
  return $.ajax({
      url: this.apiUrl + 'delete-store-products/' + magasinId,
      type: 'DELETE',
      contentType: 'application/json; charset=utf-8',
      headers: {
          'Authorization': this.elasticAuth,
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Headers': '*',
          'Accept': 'application/json, text/plain'
      }
  });
}

public deleteStoreProductsByCategory(store, category) {
    return $.ajax({
        url: this.apiUrl + 'products/category/' + store.id + '/' + category,
        type: 'delete',
        contentType: 'application/json; charset=utf-8',
        headers: {
            'Authorization': this.elasticAuth,
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Headers': '*',
            'Accept': 'application/json, text/plain'
        }
    });
}

public getDashboardStatistics(fromDate: any, toDate: any) {
  return $.ajax({
      url: this.apiUrl + 'dashboard/statistics?from_date='+fromDate+'&to_date='+ toDate,
      type: 'GET',
      contentType: 'application/json; charset=utf-8',
      headers: {
          'Authorization': this.elasticAuth,
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Headers': '*',
          'Accept': 'application/json, text/plain'
      }
  });
}

public formatDate(date: Date) {
  if (typeof date === 'string') {
      date = new Date(date);
  }
  const month = date.getMonth() + 1;
  const dateToReturn: string = date.getDate() + '-' + (month < 10 ? '0' + month : month)  + '-' +
      date.getFullYear();
  return dateToReturn;
}

public formatSqlDate(date: Date) {
  const year = date.getFullYear();
  let month: any = date.getMonth() + 1;
  month = month > 9 ? month : '0' + month;
  let day: any = date.getDate();
  day = day > 9 ? day : '0' + day;
  let hours: any = date.getHours();
  hours = hours > 9 ? hours : '0' + hours;
  let minutes: any = date.getMinutes();
  minutes = minutes > 9 ? minutes : '0' + minutes;
  let secondes: any = date.getSeconds();
  secondes = secondes > 9 ? secondes : '0' + secondes;
  return year + '-' + month + '-' + day + ' ' + hours + ':' + minutes + ':' + secondes;
}


public refreshStoreOrders() {
  this.storeOrdersChanged.next('change');
}

public refreshStoreSales() {
  this.storeSalesChanged.next('change');
}


public generateMaystroPremiumProducts(storeId: any) {
  return $.ajax({
      url: this.maystroUrl + 'products/generate/' + storeId,
      type: 'POST',
      contentType: 'application/json; charset=utf-8',
      headers: {
          'Authorization': this.elasticAuth,
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Headers': '*',
          'Accept': 'application/json, text/plain'
      },
  });
}

public sendPremiumProductsToMaystro(storeId: any) {
  return $.ajax({
      url: this.maystroUrl + 'products/premium/send/' + storeId,
      type: 'POST',
      contentType: 'application/json; charset=utf-8',
      headers: {
          'Authorization': this.elasticAuth,
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Headers': '*',
          'Accept': 'application/json, text/plain'
      },
  });
}

public sendPremiumOrderToMaystro(orderId: any) {
  return $.ajax({
      url: this.maystroUrl + 'orders/premium/send/' + orderId,
      type: 'POST',
      contentType: 'application/json; charset=utf-8',
      headers: {
          'Authorization': this.elasticAuth,
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Headers': '*',
          'Accept': 'application/json, text/plain'
      },
  });
}

public sendStarterOrderToMaystro(orderId: any) {
  return $.ajax({
      url: this.maystroUrl + 'orders/starter/send/' + orderId,
      type: 'POST',
      contentType: 'application/json; charset=utf-8',
      headers: {
          'Authorization': this.elasticAuth,
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Headers': '*',
          'Accept': 'application/json, text/plain'
      },
  });
}

public getCategories(forFreeDelivery:any = 0, isFreeDelivery: any = 0) {
  const options: any = {};
  if(forFreeDelivery){
    options.forFreeDelivery = true;
    options.isFreeDelivery = isFreeDelivery
  }
  return $.ajax({
      url: this.apiUrl+'categories',
      type: 'GET',
      contentType: 'application/json; charset=utf-8',
      headers: {
        'Authorization': this.elasticAuth,
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Headers': '*',
          'Accept': 'application/json, text/plain'
      },
      data: options
  });
}
public updateCategories(values) {

  return $.ajax({
        url: this.apiUrl+'categories/'+1,
        type: 'PUT',
        contentType: 'application/json; charset=utf-8',
        headers: {
          'Authorization': this.elasticAuth,
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Headers': '*',
            'Accept': 'application/json, text/plain'
        },
        data: JSON.stringify(values),
    });
  }
public getCategoriesList() {

    return $.ajax({
        url: this.apiUrl + 'categories-for-coupon',
        type: 'GET',
        contentType: 'application/json; charset=utf-8',
        headers: {
          'Authorization': this.elasticAuth,
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Headers': '*',
            'Accept': 'application/json, text/plain'
        }
    });

  }
public getBookCategories() {
  return $.ajax({
      url: this.apiUrl+'book-categories',
      type: 'GET',
      contentType: 'application/json; charset=utf-8',
      headers: {
        'Authorization': this.elasticAuth,
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Headers': '*',
          'Accept': 'application/json, text/plain'
      }
  });

}

public createBookCategories(category: any) {
  return $.ajax({
      url: this.apiUrl+'book-categories',
      type: 'POST',
      contentType: 'application/json; charset=utf-8',
      headers: {
        'Authorization': this.elasticAuth,
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Headers': '*',
          'Accept': 'application/json, text/plain'
      },
      data: JSON.stringify(category)
  });

}

public getCoupons(page: number,keyword: string, options = {}) {
    return $.ajax({
        //url: this.apiUrl+'all-users',
        url: this.apiUrl+'coupons?page='+page +'&keyword='+keyword,
        type: 'GET',
        contentType: 'application/json; charset=utf-8',
        headers: {
          'Authorization': this.elasticAuth,
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Headers': '*',
            'Accept': 'application/json, text/plain'
        },
        data: options
    });

  }

  public deleteCoupon(coupon: Coupon) {
    return $.ajax({
        url: this.apiUrl + 'coupons/' + coupon.id,
        type: 'DELETE',
        contentType: 'application/json; charset=utf-8',
        headers: {
            'Authorization': this.elasticAuth,
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Headers': '*',
            'Accept': 'application/json, text/plain'
        },
        data: JSON.stringify(coupon)
    });
  }


  public createCoupon(coupon: any) {
    return $.ajax({
        url: this.apiUrl + 'coupons',
        type: 'POST',
        contentType: 'application/json; charset=utf-8',
        headers: {
            'Authorization': this.elasticAuth,
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Headers': '*',
            'Accept': 'application/json, text/plain'
        },
        data: JSON.stringify(coupon)

    });
  }

  public getCouponByCode(code) {
    return $.ajax({
        url: this.apiUrl + 'coupons/filter/' + code,
        type: 'GET',
        contentType: 'application/json; charset=utf-8',
        headers: {
            'Authorization': this.elasticAuth,
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Headers': '*',
            'Accept': 'application/json, text/plain'
        }
    });
  }


public getInfluencersCoupons(page: number,keyword: string, options = {}) {
    return $.ajax({
        url: this.apiUrl + 'influencers_coupons?page='+page+'&keyword='+keyword,
        type: 'GET',
        contentType: 'application/json; charset=utf-8',
        headers: {
            'Authorization': this.elasticAuth,
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Headers': '*',
            'Accept': 'application/json, text/plain'
        },
        data: options
    });
  }

/**
 * public setCommission0() {
  return $.ajax({
      url: this.apiUrl + 'payments/filter/stores',
      type: 'GET',
      contentType: 'application/json; charset=utf-8',
      headers: {
          'Authorization': this.elasticAuth,
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Headers': '*',
          'Accept': 'application/json, text/plain'
      }
  });
}
public setCommission5() {
  return $.ajax({
      url: this.apiUrl + 'setComissions',
      type: 'GET',
      contentType: 'application/json; charset=utf-8',
      headers: {
          'Authorization': this.elasticAuth,
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Headers': '*',
          'Accept': 'application/json, text/plain'
      }
  });
}
 */
public getPaymentsStatistics(page: number,fromDate: any, toDate: any) {
  return $.ajax({
      url: this.apiUrl + 'dashboard/statistics/payments?page='+page+'&from_date='+fromDate+'&to_date='+ toDate,
      type: 'GET',
      contentType: 'application/json; charset=utf-8',
      headers: {
          'Authorization': this.elasticAuth,
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Headers': '*',
          'Accept': 'application/json, text/plain'
      }
  });
}

public getTotalIncomes(fromDate: any, toDate: any) {
  return $.ajax({
      url: this.apiUrl + 'dashboard/statistics/getIncomes?from_date='+fromDate+'&to_date='+ toDate,
      type: 'GET',
      contentType: 'application/json; charset=utf-8',
      headers: {
          'Authorization': this.elasticAuth,
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Headers': '*',
          'Accept': 'application/json, text/plain'
      }
  });
}

public getAllDiscountedProducts() {
  return $.ajax({
      url: this.apiUrl + 'promotions',
      type: 'GET',
      contentType: 'application/json; charset=utf-8',
      headers: {
          'Authorization': this.elasticAuth,
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Headers': '*',
          'Accept': 'application/json, text/plain'
      },
  });
}

public runMigrations() {
  return $.ajax({
      url: this.apiUrl + 'run/migrations',
      type: 'POST',
      contentType: 'application/json; charset=utf-8',
      headers: {
          'Authorization': this.elasticAuth,
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Headers': '*',
          'Accept': 'application/json, text/plain'
      },
  });
}
public generateOrderProductsRelationships() {
  return $.ajax({
      url: this.apiUrl + 'generate-order-product-relationships',
      type: 'GET',
      contentType: 'application/json; charset=utf-8',
      headers: {
          'Authorization': this.elasticAuth,
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Headers': '*',
          'Accept': 'application/json, text/plain'
      },
  });
}


public clearCache() {
    return $.ajax({
        url: this.apiUrl + 'run/clear-cache',
        type: 'POST',
        contentType: 'application/json; charset=utf-8',
        headers: {
            'Authorization': this.elasticAuth,
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Headers': '*',
            'Accept': 'application/json, text/plain'
        },
    });
  }
public refreshProductsDiscounts() {
  return $.ajax({
      url: this.apiUrl + 'refresh-products-discounts',
      type: 'POST',
      contentType: 'application/json; charset=utf-8',
      headers: {
          'Authorization': this.elasticAuth,
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Headers': '*',
          'Accept': 'application/json, text/plain'
      },
  });
}


public getCashRegister(page: number, options = {}) {
  return $.ajax({
      //url: this.apiUrl+'all-users',
      url: this.apiUrl+'caisse?page='+page,
      type: 'GET',
      contentType: 'application/json; charset=utf-8',
      headers: {
        'Authorization': this.elasticAuth,
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Headers': '*',
          'Accept': 'application/json, text/plain'
      },
      data: options
  });

}
public createNewOperation(operation: any) {
  return $.ajax({
      url: this.apiUrl + 'caisse',
      type: 'POST',
      contentType: 'application/json; charset=utf-8',
      headers: {
          'Authorization': this.elasticAuth,
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Headers': '*',
          'Accept': 'application/json, text/plain'
      },
      data: JSON.stringify(operation)

  });
}

public getPublishingHouses(page: number, options = {}) {
  return $.ajax({
      //url: this.apiUrl+'all-users',
      url: this.apiUrl+'editions?page='+page,
      type: 'GET',
      contentType: 'application/json; charset=utf-8',
      headers: {
        'Authorization': this.elasticAuth,
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Headers': '*',
          'Accept': 'application/json, text/plain'
      },
      data: options
  });

}

public deleteEdition(edition: PublishingHouse) {
  return $.ajax({
      url: this.apiUrl + 'editions/' + edition.id,
      type: 'DELETE',
      contentType: 'application/json; charset=utf-8',
      headers: {
          'Authorization': this.elasticAuth,
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Headers': '*',
          'Accept': 'application/json, text/plain'
      },
      data: JSON.stringify(edition)
  });
}


public createPublishingHouse(publishingHouse: any) {
  return $.ajax({
      url: this.apiUrl + 'editions',
      type: 'POST',
      contentType: 'application/json; charset=utf-8',
      headers: {
          'Authorization': this.elasticAuth,
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Headers': '*',
          'Accept': 'application/json, text/plain'
      },
      data: JSON.stringify(publishingHouse)

  });
}

public getAllBooks(page: number, keyword: string, options = {}) {
  return $.ajax({
      url: this.apiUrl + 'books?page=' + page + '&keyword=' + keyword,
      type: 'GET',
      contentType: 'application/json; charset=utf-8',
      headers: {
          'Authorization': this.elasticAuth,
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Headers': '*',
          'Accept': 'application/json, text/plain'
      },
      data: options
  });
}
/**
 * public generateBooks() {
  return $.ajax({
      url: this.apiUrl + 'books/filter/generate-books',
      type: 'POST',
      contentType: 'application/json; charset=utf-8',
      headers: {
          'Authorization': this.elasticAuth,
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Headers': '*',
          'Accept': 'application/json, text/plain'
      }

  });
}
 */

public updateBook(book: Product) {
  return $.ajax({
      url: this.apiUrl + 'books/' + book.id,
      type: 'PUT',
      contentType: 'application/json; charset=utf-8',
      headers: {
          'Authorization': this.elasticAuth,
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Headers': '*',
          'Accept': 'application/json, text/plain'
      },
      data: JSON.stringify(book)
  });
}
public fixStoreBook() {
  return $.ajax({
      url: this.apiUrl + 'books/fix',
      type: 'GET',
      contentType: 'application/json; charset=utf-8',
      headers: {
          'Authorization': this.elasticAuth,
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Headers': '*',
          'Accept': 'application/json, text/plain'
      },
  });
}
public fixPictures(){
  return $.ajax({
    url: this.apiUrl + 'fix-book-pictures',
    type: 'GET',
    contentType: 'application/json; charset=utf-8',
    headers: {
        'Authorization': this.elasticAuth,
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Headers': '*',
        'Accept': 'application/json, text/plain'
    },
});
}
public deleteBook(book: Product) {
  return $.ajax({
      url: this.apiUrl + 'books/' + book.id,
      type: 'DELETE',
      contentType: 'application/json; charset=utf-8',
      headers: {
          'Authorization': this.elasticAuth,
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Headers': '*',
          'Accept': 'application/json, text/plain'
      },
      data: JSON.stringify(book)
  });
}
public createBook(book: Product) {
  return $.ajax({
      url: this.apiUrl + 'books',
      type: 'POST',
      contentType: 'application/json; charset=utf-8',
      headers: {
          'Authorization': this.elasticAuth,
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Headers': '*',
          'Accept': 'application/json, text/plain'
      },
      data: JSON.stringify(book)
  });
}
public createCard(card:any) {
  return $.ajax({
      url: this.apiUrl + 'cards',
      type: 'POST',
      contentType: 'application/json; charset=utf-8',
      headers: {
          'Authorization': this.elasticAuth,
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Headers': '*',
          'Accept': 'application/json, text/plain'
      },
      data: JSON.stringify(card)
  });
}
public createBookmarkedStore(bookmark:any) {
  return $.ajax({
      url: this.apiUrl + 'bookmarkedStores',
      type: 'POST',
      contentType: 'application/json; charset=utf-8',
      headers: {
          'Authorization': this.elasticAuth,
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Headers': '*',
          'Accept': 'application/json, text/plain'
      },
      data: JSON.stringify(bookmark)
  });
}
public createBookmarkedProduct(bookmark:any) {
  return $.ajax({
      url: this.apiUrl + 'bookmarkedProducts',
      type: 'POST',
      contentType: 'application/json; charset=utf-8',
      headers: {
          'Authorization': this.elasticAuth,
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Headers': '*',
          'Accept': 'application/json, text/plain'
      },
      data: JSON.stringify(bookmark)
  });
}
public updateUser(user: any) {
  if (user.created_at) {
      delete user.created_at;
  }

  if (user.updated_at) {
      delete user.updated_at;
  }
  return $.ajax({
      url: this.apiUrl + 'users/' + user.id,
      type: 'PUT',
      contentType: 'application/json; charset=utf-8',
      headers: {
          'Authorization': this.elasticAuth,
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Headers': '*',
          'Accept': 'application/json, text/plain'
      },
      data: JSON.stringify(user)
  });
}
}
