export const environment = {
  production: true,
  
  firebaseConfig : {
    apiKey: 'AIzaSyDk-TSnQz6MP_syNbNm4QZZaa6WH_pX844',
    authDomain: 'placetta-mobile.firebaseapp.com',
    databaseURL: 'https://placetta-mobile.firebaseio.com',
    projectId: 'placetta-mobile',
    storageBucket: 'placetta-mobile.appspot.com',
    messagingSenderId: '1010305209726',
    appId: '1:1010305209726:web:948c787699e1d56648cf2f',
    measurementId: 'G-SR8XV91GM7'
  },
};


/**
 * firebaseConfig : {
    apiKey: "AIzaSyBRSOScXRRP6H_QqALxgNL6l7km0KiWi_Q",
    authDomain: "placetta-admin.firebaseapp.com",
    databaseURL: "https://placetta-admin.firebaseio.com",
    projectId: "placetta-admin",
    storageBucket: "placetta-admin.appspot.com",
    messagingSenderId: "481444645597",
    appId: "1:481444645597:web:e6d013730ae50ce3ff6934"
  },
 */