import { BrowserModule } from '@angular/platform-browser';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatNativeDateModule, MatRippleModule, MAT_DATE_LOCALE} from '@angular/material/core';
import {MatCardModule} from '@angular/material/card';
import {MatTableModule} from '@angular/material/table';
import { AngularFireModule } from '@angular/fire';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { environment } from '../environments/environment';
import { LoginComponent } from './login/login.component';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {MatButtonModule} from '@angular/material/button';
import { CommonModule, HashLocationStrategy, LocationStrategy } from '@angular/common';
import {MatMenuModule} from '@angular/material/menu';
import {MatIconModule} from '@angular/material/icon';
import { HttpClientModule } from '@angular/common/http';
import { MatSortModule } from '@angular/material/sort';
import { MatPaginatorModule } from '@angular/material/paginator';
import { IonicModule } from '@ionic/angular';
import {MatSelectModule} from '@angular/material/select';
import {MatDatepickerModule} from '@angular/material/datepicker';
import { PaymentsComponent } from './components/payments/payments.component';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatTabsModule} from '@angular/material/tabs';
import {MatRadioModule} from '@angular/material/radio';
import { PayStoreComponent } from './modals/pay-store/pay-store.component';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import { OrderPaymentComponent } from './modals/order-payment/order-payment.component';
import { PayStoresConfirmationComponent } from './modals/pay-stores-confirmation/pay-stores-confirmation.component';
import { HomeComponent } from './components/home/home.component';
import {MatTooltipModule} from '@angular/material/tooltip';
import { CashRegisterComponent } from './components/cash-register/cash-register.component';
import { CashRegisterFormComponent } from './modals/cash-register-form/cash-register-form.component';
import { LocationAccuracy } from '@ionic-native/location-accuracy/ngx';
import { Geolocation } from '@ionic-native/geolocation/ngx';
import { NativeGeocoder } from '@ionic-native/native-geocoder/ngx';
import { Ng2ImgMaxModule } from 'ng2-img-max';
import { ImageCropperModule } from 'ngx-image-cropper';
import { PaymentsHistoryComponent } from './modals/payments-history/payments-history.component';
import { OrderComponent } from './modals/order/order.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    OrderComponent,
    PaymentsComponent,
    PayStoreComponent,
    OrderPaymentComponent,
    PayStoresConfirmationComponent,
    HomeComponent,
    CashRegisterComponent,
    CashRegisterFormComponent,
    PaymentsHistoryComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    MatSortModule,
    ImageCropperModule,
    Ng2ImgMaxModule,
    MatPaginatorModule,
    MatSidenavModule,
    MatRippleModule,
    MatCardModule,
    MatTableModule,
    MatTooltipModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatSelectModule,
    MatAutocompleteModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatSlideToggleModule,
    MatExpansionModule,
    MatCheckboxModule,
    MatTabsModule,
    CommonModule,
    MatIconModule,
    MatMenuModule,
    MatRadioModule,
    AngularFireModule,
    AngularFireDatabaseModule,
    AngularFireStorageModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    BrowserAnimationsModule,
    IonicModule.forRoot()
  ],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
    {provide: LocationStrategy, useClass: HashLocationStrategy},
    { provide: Geolocation },
    { provide: LocationAccuracy},
    NativeGeocoder
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }
