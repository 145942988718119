import {Address} from './Address';
import {Review} from './Review';

export class User {
    public id: string;
    public firstName: string;
    public lastName: string;
    public fullName: string;
    public email: string;
    public password: string;
    public address: string;
    public mobile: number;
    public country: string;
    public accountType: string;
    public phoneWithArea: string;
    public emailPhoneAreaFilter: string;
    public wilaya: string;
    public wilayaCode: number;
    public commune: string;
    public communeId: number;
    public picture: string;
    public lat: number;
    public lng: number;
    public phoneVerification: boolean;
    public isValidated: boolean;
    public addressList: Address[];
    public reviews: Review[];
    public ratingAverage: number;
    public notifications: Notification[];
    public deliveryPrice: number;
    public codeCommune: string;
    public hasFreeDelivery: boolean;
    public createdAt: any;
    public createdAtTime: number;
    public created_at: any;
    public updated_at: any;
    constructor() {

    }
}
