<div class="close-btn">
  <ion-icon  (click)="close()" name="close-outline"></ion-icon>
</div>
<div class="form-container">
   <h2>Add New Operation</h2>
   <form #form>
    <div class="row">
        <div class="col-12">
            <div class="col-md-6 col-12">
               <p><strong>Type :</strong></p>
                <mat-radio-group class="operation-type" [(ngModel)]="selectedType" name="operation">
                    <mat-radio-button value="income" color="primary">Income</mat-radio-button>
                    <mat-radio-button value="outcome" color="primary">Outcome</mat-radio-button>
                  </mat-radio-group>
                  <p class="error-text" *ngIf="validationText == 'typeRequired'">select a type</p>
            </div>
        </div>
        <div class="col-md-6 col-12">
            <mat-form-field appearance="outline" class="full-width">
                <mat-label>User</mat-label>
                <input matInput placeholder="user" [value]="user.fullName" disabled>
              </mat-form-field>
        </div>
        <div class="col-md-6 col-12">
            <mat-form-field appearance="outline" class="full-width">
                <mat-label>Operation</mat-label>
                <input type="text" placeholder="operation" aria-label="Operation"
                       matInput [formControl]="operationControl">
                <!-- 
                  <input type="text" placeholder="operation" aria-label="Operation"
                       matInput [formControl]="operationControl" [matAutocomplete]="auto">
                <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
                  <mat-option *ngFor="let operation of filteredOperations | async" [value]="operation">
                    {{operation}}
                  </mat-option>
                </mat-autocomplete>
                -->
              </mat-form-field>
        <p class="error-text" *ngIf="validationText == 'operationRequired'">operation is required</p>
        </div>
        <div class="col-md-6 col-12 amount-caisse">
            <mat-form-field appearance="outline" floatLabel="always" class="full-width">
                <div class="currency-list-background"></div>
                <mat-label>Amount</mat-label>
                <input matInput type="number" class="amount-field" placeholder="0" [formControl]="amountControl">
                
                    <mat-select class="currency-list" [(value)]="selectedCurrency" (selectionChange)="getSelectedValue($event)">
                      <mat-option *ngFor="let currency of currencies" [value]="currency">
                        {{currency}}
                      </mat-option>
                    </mat-select>
              </mat-form-field>
        <p class="error-text" *ngIf="validationText == 'amountRequired'">amount is required</p>

              
        </div>
        <div class="col-md-6 col-12" [ngClass]="{'d-none': selectedCurrency == 'DA'}">
            <mat-form-field appearance="outline" class="full-width">
                <mat-label>Rate</mat-label>
                <input matInput type="number" class="rate-field" placeholder="0" [formControl]="rateControl">
              </mat-form-field>
        <p class="error-text" *ngIf="validationText == 'rateRequired'">rate is required</p>
              
        </div>
        <div class="col-12">
            <mat-form-field appearance="outline" class="full-width">
                <mat-label>Notes</mat-label>
                <textarea matInput [formControl]="noteControl"></textarea>
              </mat-form-field>
        </div>
        <div class="col-12">
          <button mat-raised-button (click)="save(form)" class="save-btn">Save</button>
          <div class="alert alert-success text-center message-sent-success">
            Done !
          </div>
          <div class="alert alert-fail text-center message-sent-fail">
            Error While Creating Operation !
          </div>
        </div>
    </div>
</form>
</div>
<div class="loader-section loader pos-absolute display-flex-center
 ion-text-center full-width" *ngIf="showLoader">
    <img src="../../../assets/images/loader-gray.gif"/>
  </div>