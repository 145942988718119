import { AfterViewInit, Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { Router } from '@angular/router';
import { User } from 'src/app/models/User';
import { AuthService } from 'src/app/shared/auth.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, AfterViewInit {

  public activeItem: any;
  title = 'admin';
  public mode: any;
  public isOpened: boolean;
  public hasBackdrop: boolean;
  public user: User;
@ViewChild('sidenav') sidenav: MatSidenav;
  /**
   * public links: any[] = [{url:'dashboard',icon: 'dashboard',title: 'Dashboard'},
  {url:'customers',icon: 'people',title: 'Customers'},
  {url:'stores',icon: 'store',title: 'Stores'},
  {url:'products',icon: 'local_offer',title: 'Products'},
  {url:'orders',icon: 'description',title: 'Orders'},
  {url:'payments',icon: 'description',title: 'Payments'},
  {url:'operations',icon: 'settings',title: 'Operations'},
  {url:'promotions',icon: 'megaphone',title: 'Promotions'}]
    */


  constructor(public firebaseService: AuthService, private router: Router){
  }

  ngOnInit(): void {
    this.user = new User();
    if (localStorage.getItem('user')) {
      this.user = JSON.parse(localStorage.getItem('user'));
    }
    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    //Add 'implements OnInit' to the class.
    const currentPage: string=this.router.url;
    console.log(this.router.url);
    if(currentPage==='/home/cash-register'){
      this.activeItem='cash-register';
    }
    // else if(currentPage==='/home/payments'){
    //   this.activeItem='payments';
    // }
    else{
      this.activeItem = 'cash-register';
    }

  }
  ngAfterViewInit(): void {
    setTimeout(() => {
    this.onResize();

    }, 0);
  }
goTo(item:string){
  this.activeItem = item;
  this.router.navigate(['/home/'+item]);
}
logout(){
  this.firebaseService.logout()
}
toggleSideNav(){
  this.sidenav.toggle();
  this.isOpened = !this.isOpened;
}
@HostListener('window:resize', [])
    onResize() {
        const windowWidth = window.innerWidth;
        if (windowWidth > 768) {
          this.mode = 'side';
          this.sidenav.open();
          this.isOpened = true;
          this.hasBackdrop = false;
        }
        else{
          this.mode = 'over';
          this.sidenav.close();
          this.isOpened = false;
          this.hasBackdrop = true;
        }
    }

}
